
import { defineComponent, ref, onMounted, computed, PropType, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  name: "EventGenderChartTraining",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    training_type: String,
    widgetClasses: String,
    genderData: {
      type: Array as PropType<{ label: string; data: number }[]>,
      required: true,
    },
  },
  setup(props) {
    const list = ref([]);
    const series = ref(props.genderData.map((item) => item.data));

    watch(() => props.genderData, (newGenderData) => {
      series.value = newGenderData.map((item) => item.data);
    });

    const total = computed(() => {
      return series.value.reduce((acc, value) => {
        if (typeof value === 'number') {
          return acc + value;
        } else {
          return acc;
        }
      }, 0);
    });
    const title = computed(() => {
      if (props.training_type === 'Apprenticeship formal') {
        return "Number of formal apprenticeship beneficiaries by sector";
      } else {
        return "Number of informal apprenticeship beneficiaries by sector";
      }
    });
    const chartOptions = computed(() => {
      return {
        chart: {
          type: "pie",
        },
        labels:  [...props.genderData.map((item) => `${item.label} - ${item.data}`), `Total - ${total.value}`],
      };
    });

    const loadData = async () => {
      try {
        const training_type = props.training_type;

        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DASHBOARD_TRAINING_INSTITUTE_EVENT_WISE_BENEFICIARY, {
          training_type: training_type

        }
        );

        list.value = response.data.data;
        console.log(list)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(loadData);

    return {
      chartOptions,
      series,
      list,
      title,
    };
  },
});
